/* .App {
  text-align: center;
} */



body {
  font-family: Arial;
  padding: 10px;
  background: #f1f1f1;
}

.nav {
  text-decoration: rgb(0, 250, 250);
}

/* Add a black background color to the top navigation */
.topnav {
  background-color: #A5A3C1;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 15px 30px;
  text-decoration: none;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: rgb(76, 139, 175);
  color: white;
}

.topnav a.logo {
  background-image: url('./components/brandName.png');
  background-size: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header2 {
  background-image: url("https://i.pinimg.com/originals/b7/97/30/b797305e2dbcc14fc6851b4f8db0b0f3.gif");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 253, 253);
}

.App-header {
  font-size: calc(5px + 2vmin);
  color: slategray;
  text-align: center;
  height: 7vh;
  
}

.App-header-end {
  float: right;
  align-items: center;
}

.App-main-left {
  /* background-image: url('./components/fav.jpg') ; */
  float: left;
  align-items: center;
  color: aquamarine;
  font-size: calc(10px + 2vmin);
}

.App-main-right {
  float: right;
  margin-right: 10vh;
  font-size: calc(10px + 2vmin);
  color: slategray;
  max-width:100%; 
}

.App-footer {
  background-color: darkgray;
  color: slategray;
  text-align: center;
  height: 30vh;
}

.logo-image {
  margin-top: 10%;
  margin-left: 20%;
}

.App-link {
  color: #f53809;
}

/* Create two unequal columns that floats next to each other */
/* Left column */
.center {
  width: 100%;
}

.main-logo {
  width: 50%;
  text-align: center;
  float: left
}

.main-right {
  float: left;
  text-align: left;
  width: 49%;
}

.leftcolumn {   
  float: left;
  text-align: left;
  width: 50%;
}

/* Right column */
.rightcolumn {
  float: left;
  width: 49%;
  background-color: #f1f1f1;
  padding-left: 15px;
}

.contact-left {
  float: left;
  width: 5%;
}

.contact-right {
  float: left;
}

/* Add a card effect for articles */
.card {
  background-color: #e6e5f5;
  background-size: flex;
  padding: 20px;
  margin-top: 20px;
}


/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
